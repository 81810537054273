import React from 'react';
import './App.css';
import SimonSays from "./pages/SimonSays";

function App() {
  return (
    <div className="App">
      <SimonSays />
    </div>
  );
}

export default App;
